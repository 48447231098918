// polyfill needs to be imported before anything else
import "$ui-components/polyfill";

import {
	PUBLIC_COGNITO_CLIENT_ID,
	PUBLIC_COGNITO_POOL_ID,
	PUBLIC_STAGE,
	PUBLIC_VERSION,
	PUBLIC_RUN_MODE
} from "$env/static/public";
import { configureEnvironment } from "$ui-components/utils";
configureEnvironment({
	stage: PUBLIC_STAGE,
	runMode: PUBLIC_RUN_MODE as any
});
import { registerErrorHandlers } from "$ui-components/utils";
import * as Sentry from "@sentry/svelte";
import type { HandleClientError } from "@sveltejs/kit";
import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

Sentry.init({
	attachStacktrace: true,
	dsn: "https://bacce5100c234cf98e182e57af593ba0@o776661.ingest.sentry.io/6710651",
	enabled: import.meta.env.PROD,
	environment: PUBLIC_STAGE,
	integrations: [],
	release: PUBLIC_VERSION,
	tracesSampleRate: 0
});

registerErrorHandlers();

function getParsedJwt(token: string) {
	try {
		return JSON.parse(atob(token.split(".")[1]));
	} catch (e) {
		return null;
	}
}

export class CustomStorage {
	async setItem(key: string, value: any) {
		if (key.endsWith(`.idToken`)) {
			const oldIdToken = localStorage.getItem(key);
			if (oldIdToken) {
				let parsedOld = getParsedJwt(oldIdToken);
				let parsedJwt = getParsedJwt(value);
				if (parsedOld && parsedJwt) {
					if ("admin_id" in parsedOld && "admin_id" in parsedJwt === false) {
						signOut();

						setTimeout(() => {
							window.location.reload();
						}, 1000);
					}
				}
			}
		}
		localStorage.setItem(key, value);
	}
	async getItem(key: string) {
		return localStorage.getItem(key);
	}
	async removeItem(key: string) {
		localStorage.removeItem(key);
	}

	async clear() {
		localStorage.clear();
	}
}

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolClientId: PUBLIC_COGNITO_CLIENT_ID.replace("https://", ""),
			userPoolId: PUBLIC_COGNITO_POOL_ID,
			loginWith: {
				username: false,
				phone: false,
				email: true
			},
			signUpVerificationMethod: "code"
		}
	}
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CustomStorage());

export const handleError: HandleClientError = (({ error, event }) => {
	Sentry.captureException(error, { contexts: { sveltekit: { event } } });
	return { message: error.message };
}) satisfies HandleClientError;
